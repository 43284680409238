import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';
import { CouponComponent } from '../../coupon/coupon.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { Clipboard } from '@angular/cdk/clipboard';
import { ApplyCouponComponent } from 'src/app/shared/apply-coupon/apply-coupon.component';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Constant } from 'src/app/constants/constant';

@Component({
  selector: 'calsoft-subscription-price',
  templateUrl: './subscription-price.component.html',
  styleUrls: ['./subscription-price.component.scss']
})
export class SubscriptionPriceComponent implements OnInit {


  @Input() type: any = 1;

  @Input() item: any;

  subscriptionCost: any = 0.0;
  taxPercentage: any = 0.0;
  totalCost: any = 0.0;
  taxAmount: any;
  tax: boolean;
  signupCost: any;
  setupCost: any;
  couponList = []; 
  couponResult: any;
  discountEnabled: boolean;
  discountAmt: any;
  subscriptionDiscount:any = 0.0;

  constructor(private subscriptionService : SubscriptionPriceService,  private checkOut: CheckoutService, private dialog: MatDialog, private clipboard: Clipboard,
    private snackBar: MatSnackBar) { }


  openCouponDialog() {
    const dialogRef = this.dialog.open(ApplyCouponComponent, {
      width: '80%', 
      maxWidth: '600px',
      data: { couponList: this.couponList } 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setPromoCode(result);
      }
    });
  }
  promoCode: string = 'Select Coupon First'; 

  setPromoCode(coupon: string) {
    this.promoCode = coupon;
    this.isPromoApplied = false;
  }
  
    applyCouponCode(){
      let coupon = {
        "customerId": 0,
        "shippingMethod": "store pickup",
        "couponCode": this.promoCode,
        "subTotal": this.calculateTotal(),
        "grandTotal": this.calculateTotal,
        "totalQty": 0,
        "weight": 0,
  
      }
    
      let url = "applyCoupon"
      this.checkOut[url](coupon).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {

            let amt = response['data']['discountAmt'];
            this.couponResult = response['data'];
            this.discountEnabled = true;
            this.discountAmt = response['data']['discountAmt']|| 0;

            this.subscriptionService.updateDiscount(this.discountAmt);
  
          }
          else {
  
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { });
    }
  applyPromoCode() {
    if (this.isPromoApplied) {
  
      this.promoCode = "Select Coupon First"; 
      this.isPromoApplied = false; 
      this.discountAmt = 0;
      return;
    }
  
    if (this.promoCode && this.promoCode !== "Select Coupon First") {
    
      this.isPromoApplied = true; 
       this.applyCouponCode(); 
      this.copyInputMessage(this.promoCode);
    } else {
      this.promoCode = "Select Coupon First"; 
    }
  }

  isPromoApplied: boolean = false; 

  copyInputMessage(code: string) {
    this.clipboard.copy(code);
    this.snackBar.open(`Coupon Code Copied`, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
  }

  dailyCost:any;

  totaldailyCost:any;


  ngOnInit() {

    if(this.item.customField22=='Exclusive'){
      this.tax = true;
    }else{
      this.tax = false
    }

    localStorage.setItem('subscriptionDiscount', '0');

    localStorage.setItem('subscriptionAdditionalDiscount', '0');

    this.subscriptionService.subscriptionPrice$.subscribe(price => {
      this.subscriptionCost = price;
      this.updateDailyTotal();
    });

    this.subscriptionService.subscriptionTaxPercentage$.subscribe(taxPercentage => {
      this.taxPercentage = taxPercentage;
    });

    this.subscriptionService.subscriptionTotalCost$.subscribe(totalCost => {
      this.totalCost = totalCost;
    });

    this.subscriptionService.subscriptionTotalTaxAmount$.subscribe(taxAmount => {
      this.taxAmount = taxAmount;
    });
    this.subscriptionService.subscriptionSetupCost$.subscribe(setupCost => {
      this.setupCost = setupCost;
    });
    
    this.subscriptionService.subscriptionSignUpCost$.subscribe(signupCost => {
      this.signupCost = signupCost;
    });


    if(this.discountAmt>0){
      this.subscriptionService.subscriptionDiscountCost$.subscribe(discountAmount => {
        this.subscriptionDiscount = Number(discountAmount);
      });
    }else{
      localStorage.setItem('subscriptionDiscount', '0');
    }




    this.calculateTotal();

    this.updateDailyTotal()
  }


  updateDailyTotal(){
    this.totaldailyCost = (parseInt(this.subscriptionCost) + parseInt(this.signupCost));

    this.dailyCost = (this.totaldailyCost / 365 / 10).toFixed(2);
  }

  formatCurrency(cost: string | null | undefined): string {
    if (!cost) {
      return 'Invalid cost'; // Return a default value or handle it appropriately
    }
  
    const [currency, amountStr] = cost.split(' '); 
    const amount = parseInt(amountStr, 10);
  
    if (currency === 'INR') {
      return `₹ ${amount.toLocaleString()}`; 
    }
    
    return amount.toLocaleString(); 
  }
  
  extractAmount(cost: string | number): number {
    const costString = String(cost); // Convert cost to a string
  
    if (costString.includes('INR')) {
      const amount = costString.split(' ')[1].replace(/,/g, '');
      return parseFloat(amount);
    } else if (costString.includes(',')) {
      return parseFloat(costString.replace(/,/g, ''));
    } else if (!isNaN(Number(costString))) {
      return parseFloat(costString);
    }
  
    return 0;
  }
  
  
  
  calculateTotal(): number {
    let total = 0;
    
    const subscriptionCostAmount = this.extractAmount(this.subscriptionCost);  
  
    total += subscriptionCostAmount;
  
    const setupCostAmount = this.extractAmount(this.setupCost);  
    total += setupCostAmount;
  
    const signupCostAmount = this.extractAmount(this.signupCost);  
    total += signupCostAmount;
  
    if (this.tax) {
      const taxAmountExtracted = this.extractAmount(this.taxAmount);
      total += taxAmountExtracted;
    }
    if (this.discountEnabled && this.discountAmt > 0) {
      return total - this.discountAmt;
    }
  
    return total; 
  }
  


}
